import { createEventHandler } from '@wix/tpa-settings';
import { SettingsEvents } from './settings-events';

type Data = { [key: string]: any };

export function createSettingsEventHandler(data: Data) {
  const handler = createEventHandler<any>(data);
  const chainApi = {
    on<T extends SettingsEvents>(event: T | T[], cb: () => void) {
      if (typeof event === 'string') {
        handler.on(event, cb);
      } else {
        event.map((e) => handler.on(e, cb));
      }
      return this;
    },
  };
  return {
    ...chainApi,
    // reset event is emitted when settings panel is closed
    onSettingsClose: handler.onReset,
    updateData: handler.notify,
  };
}
