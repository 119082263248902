import { IWixAPI } from '@wix/yoshi-flow-editor';
import { unreachable } from '~/ts-utils';
import { encodeUrlIfNeeded } from '../Widget/utils/generate-deep-link';
import { getDynamicSection } from './get-dynamic-section';

export const MAX_RESOURCE_ID_CHARS = 128;

// compid strategy is legacy, where we used to use compid as the resource id by default
type ResourceIdStrategy = 'demo' | 'compId' | 'dynamicPage' | 'useUrl';

export const resolveResourceId = async ({
  compId,
  wixCodeApi,
  strategy,
}: {
  compId: string;
  wixCodeApi: IWixAPI;
  strategy: ResourceIdStrategy;
}) => {
  const resourceId = (() => {
    switch (strategy) {
      case 'compId':
        return compId;
      case 'dynamicPage':
        return compId + getDynamicSection(wixCodeApi.location);
      case 'useUrl':
        const fallback = '/';
        const url =
          wixCodeApi.site.currentPage?.isHomePage && wixCodeApi.site.currentPage?.url
            ? wixCodeApi.site.currentPage?.url
            : // accessing pathname will return encoded url string
              new URL(wixCodeApi.location.url).pathname;

        return url ? url.split('/').slice(1).join('_') : fallback;
      case 'demo':
        return 'demo';
      default:
        throw unreachable(strategy);
    }
  })();

  return resourceId.slice(0, MAX_RESOURCE_ID_CHARS);
};

export const resolveResourceIdStrategy = async ({
  isDemoMode,
  isPageDynamic,
  hasCategoryId,
}: {
  isDemoMode: boolean;
  isPageDynamic: boolean;
  hasCategoryId: boolean;
}): Promise<ResourceIdStrategy> => {
  switch (true) {
    case isPageDynamic:
      return 'dynamicPage';
    case hasCategoryId:
      return 'useUrl';
    case isDemoMode:
      return 'demo';
    default:
      return 'compId';
  }
};
