import { Category } from '~/services/category-service';

type Subscriber<T> = {
  callback: (newState: Partial<T>, currentState: T) => void;
  type: 'single' | 'persistent';
};

export type WidgetState = {
  resourceId: string | undefined;
  category: Category | undefined;
  isLocked: boolean;
};
export type WidgetStore = ReturnType<typeof createWidgetStore>;

export function createWidgetStore(initialState: WidgetState) {
  let state = initialState;
  const subscribers: Set<Subscriber<WidgetState>> = new Set();

  return {
    getState: () => state,
    async waitForSetResourceId(): Promise<void> {
      return new Promise((resolve) => {
        if (state.resourceId) {
          return resolve();
        }
        this.subscribe((newState) => newState.resourceId && resolve(), { once: true });
        setTimeout(() => {
          resolve();
        }, 10000);
      });
    },
    setState: (newState: Partial<WidgetState>) => {
      state = { ...state, ...newState };
      subscribers.forEach((subscriber) => {
        subscriber.callback(newState, state);
        if (subscriber.type === 'single') {
          subscribers.delete(subscriber);
        }
      });
    },
    subscribe: (callback: Subscriber<WidgetState>['callback'], options?: { once: boolean }) => {
      subscribers.add({ callback, type: options?.once ? 'single' : 'persistent' });
    },
  };
}
