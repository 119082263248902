import { WidgetStore } from '../widget-store';
import { CommentsApiHolder } from '../../create-comments-api-holder';
import { CommentsWidgetPublicApi, OnCommentCreateCallback } from './public-api.types';
import { toPublicApiComment, toPublicSettings } from './public-api.utils';

const ContentUtilsModule = import(/* webpackChunkName: "ricos-content-utils" */ './ricos-get-text');

export const createPublicApi = ({
  widgetStore,
  commentsApiHolder,
}: {
  widgetStore: WidgetStore;
  commentsApiHolder: CommentsApiHolder;
}): CommentsWidgetPublicApi => {
  return {
    set resourceId(resourceId: string) {
      widgetStore.setState({ resourceId });
    },
    get locked() {
      return widgetStore.getState().isLocked;
    },
    setResourceId(resourceId: string) {
      widgetStore.setState({ resourceId });
    },
    lock: () => {
      widgetStore.setState({ isLocked: true });
    },
    unlock: () => {
      widgetStore.setState({ isLocked: false });
    },
    openNearestCommentBox: async (options) => {
      const commentsApi = await commentsApiHolder.getApi();
      const resourceId = widgetStore.getState().resourceId;
      if (!resourceId || !commentsApi) {
        return;
      }

      commentsApi.affect.tryOpenCommentBox(resourceId, {
        shouldFocus: options?.focus,
        shouldScroll: options?.scroll,
      });
    },
    closeCommentBoxes: async () => {
      const commentsApi = await commentsApiHolder.getApi();
      const resourceId = widgetStore.getState().resourceId;
      if (!resourceId || !commentsApi) {
        return;
      }

      commentsApi.affect.tryCloseAllCommentBoxes(resourceId);
    },
    onCommentCreate: async (callback: OnCommentCreateCallback) => {
      const { getText } = await ContentUtilsModule;
      const commentsApi = await commentsApiHolder.getApi();
      commentsApi?.watch.comments.onCreate((comment) =>
        callback(toPublicApiComment(comment, (content) => getText(content).join(' '))),
      );
    },
    onRatingChange: async (callback) => {
      const commentsApi = await commentsApiHolder.getApi();
      const resourceId = widgetStore.getState().resourceId;
      if (!commentsApi || !resourceId) {
        return;
      }
      commentsApi?.watch.ratings.onChange((ratings) => {
        const ratingsState = ratings[resourceId];
        if (ratingsState.type === 'READY') {
          return callback({ average: ratingsState.average, count: ratingsState.total });
        }
      });
    },
    onCommentCountChange: async (callback) => {
      const commentsApi = await commentsApiHolder.getApi();
      const resourceId = widgetStore.getState().resourceId;
      if (!commentsApi || !resourceId) {
        return;
      }

      commentsApi.watch.pagination.onChange((pagination) => {
        const paginationState = pagination[resourceId];
        if (paginationState.type === 'READY') {
          return callback(paginationState.totals);
        }
      });
    },
    onSettingsUpdated: async (callback) => {
      widgetStore.subscribe((newState) => {
        if (newState.category) {
          callback(toPublicSettings(newState.category));
        }
      });
    },
    getSettings: async () => {
      const state = widgetStore.getState();
      if (state.category) {
        return toPublicSettings(state.category);
      }
    },
  };
};
