import { initializeCommentsController } from '@wix/comments-ooi-client/controller';
import { Duplexer } from '@wix/duplexer-js';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getCurrentInstance } from './get-current-instance';
import { getDemoContent } from './demo-content/demo-content';

export const initCommentsController = async (
  flowAPI: ControllerFlowAPI,
  {
    isDemoMode,
    appDefinitionId,
    enableWarmupDataCache,
  }: { enableWarmupDataCache: boolean; isDemoMode: boolean; appDefinitionId: string },
) => {
  const { wixCodeApi, appParams } = flowAPI.controllerConfig;

  const commentsApi = await initializeCommentsController(flowAPI.controllerConfig, {
    enableWarmupDataCache,
    httpClient: flowAPI.httpClient,
    shouldAutoBindStateToSetProps: false,
    liveUpdates:
      wixCodeApi.window.rendering.env !== 'backend' && !isDemoMode
        ? {
            duplexerClient: new Duplexer(`duplexer.wix.com`, {
              instanceUpdater: {
                getInstance: () => getCurrentInstance(wixCodeApi, appDefinitionId),
              },
            }),
          }
        : undefined,
    appDefinitionId,
    ...(isDemoMode
      ? {
          mocks: {
            fetchComments: getDemoContent({
              baseUrl: appParams.baseUrls.staticsBaseUrl,
              t: flowAPI.translations.t,
            }),
          },
        }
      : {}),
  });

  return commentsApi;
};
