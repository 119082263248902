export const getCtxFields = ({
  isBlogComments,
  resourceId,
  categoryId,
}: {
  isBlogComments: boolean;
  resourceId?: string;
  categoryId?: string;
}) => {
  const contextId = isBlogComments ? resourceId : categoryId;

  return {
    contextId: contextId ?? 'default',
    contextType: 'context-type',
  };
};
