import { v4 } from 'uuid';

type Member = { id: string; nickname: string; url: string };

export const getRichContent = (text: string) => ({
  nodes: [
    {
      type: 'PARAGRAPH',
      id: 'foo',
      nodes: [{ type: 'TEXT', id: '', nodes: [], textData: { text, decorations: [] } }],
      paragraphData: { textStyle: { textAlignment: 'LEFT' }, indentation: 0 },
    },
  ],
  metadata: {
    version: 1,
    createdTimestamp: '2023-07-11T09:49:44.320Z',
    updatedTimestamp: '2023-07-11T09:49:44.320Z',
    id: 'cebf9369-b2f2-471d-ab50-55bdbf0511d9',
  },
  documentStyle: {},
});

export const getMember = ({ id, nickname, url }: Member) => ({
  id,
  status: 'UNKNOWN',
  profile: {
    nickname,
    photo: {
      url,
    },
  },
  privacyStatus: 'PUBLIC',
  activityStatus: 'UNKNOWN',
  createdDate: '2020-09-02T11:28:30.000Z',
  updatedDate: '2022-07-07T06:18:34.810Z',
});

export const generateComment = ({
  memberId,
  richContent,
  replyingToMemberId,
  reactions,
  rating,
  isMarked = false,
}: {
  memberId: string;
  richContent: Record<any, any>;
  replyingToMemberId?: string;
  reactions?: { total: number; votes?: number; members: Member[] };
  isMarked?: boolean;
  rating?: number;
}) => {
  const date = (() => {
    const d = new Date();
    d.setDate(d.getDate() - 5);
    return d.toISOString();
  })();

  return {
    id: v4(),
    revision: '1',
    contextId: '1',
    contextType: 'context-type',
    appDefId: 'd95709a3-ab2b-44ad-b5b6-7103ba0df9f8',
    createdDate: date,
    updatedDate: date,
    resourceId: '1',
    content: {
      richContent,
      mentions: [],
      attachments: [],
      effects: [],
      resources: [],
    },
    rating,
    author: { memberId },
    ...(replyingToMemberId
      ? {
          parentComment: {
            id: 'd95709a3-ab2b-44ad-b5b6-7103ba0df9f8',
            author: {
              memberId: replyingToMemberId,
            },
          },
        }
      : {}),
    repliesCount: 0,
    status: 'PUBLISHED',
    marked: isMarked,
    voteSummary: {
      netVoteCount: reactions?.votes ?? 0,
      upvoteCount: 0,
      downvoteCount: 0,
    },
    reactionsSummary: { total: 0, reactionsCounts: [] },
  };
};

/*

    replies: {
      total: replies?.length ?? 0,
      comments: replies ? take(replies, repliesToShow ?? replies.length) : [],
      pageCursor:
        'V2.CgExEjQKMjE2NTkwODg0NDM1MjJfODQyODg2ZmMtYzhiYy00YzZiLTlmNWYtMDZjNzg1YjI2ZjM4GgIIASINCgljcmVhdGVkQXQQAUgB',
      ...(hasMoreReplies ? { nextCursor: v4() } : {}),
      paginationContext: {
        offset: 0,
        remainingCount: remainingReplies,
      },
    },
*/

export const generateReactions = (
  reactionsByCommentId: {
    commentId: string;
    total: number;
    reactionIdentities: { memberId: string }[];
  }[],
) => {
  return reactionsByCommentId.reduce(
    (acc, { commentId, total, reactionIdentities }) => ({
      ...acc,
      [commentId]: {
        total,
        reactions: [
          {
            reactionCode: ':like:',
            hasReacted: false,
            identities: reactionIdentities,
            total,
          },
          {
            reactionCode: ':wow:',
            hasReacted: false,
            identities: reactionIdentities,
            total: 1,
          },
          {
            reactionCode: ':haha:',
            hasReacted: false,
            identities: reactionIdentities,
            total,
          },
        ],
      },
    }),
    {},
  );
};
