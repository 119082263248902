import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Category, createCategoryService } from '~/services/category-service';
import { WidgetStore } from './widget-store';
import { getSettingsCategory } from './get-settings-category';
import { createPublicDataSelectors } from '~/services/public-data-service/public-data-service';
import { resolveResourceId, resolveResourceIdStrategy } from './resolve-resource-id';
import { isPageDynamic } from './is-page-dynamic';
import { registerWidget as baseRegisterWidget } from './register-widget';

type CommentsControllerFlow = Promise<
  | { type: 'BlogFlow'; fetchCategory: () => Promise<Category | undefined> }
  | {
      type: 'DefaultFlow';
      fetchCategory: () => Promise<Category | undefined>;
      registerWidget: () => void;
    }
>;

export const createCommentsControllerFlow = async ({
  flowAPI,
  isBlogComments,
  isDemoMode,
  widgetStore,
}: {
  flowAPI: ControllerFlowAPI;
  isBlogComments: boolean;
  isDemoMode: boolean;
  widgetStore: WidgetStore;
}): CommentsControllerFlow => {
  const { config, wixCodeApi, compId } = flowAPI.controllerConfig;

  const categoryService = createCategoryService({
    httpClient: flowAPI.httpClient,
    isBlogComments,
  });

  const fetchCategory = async (categoryId?: string) => {
    const category = await getSettingsCategory({
      targetCategoryId: categoryId,
      categoryService,
    });

    return category;
  };

  if (isBlogComments) {
    const category = await fetchCategory();
    widgetStore.setState({ category });
    return { type: 'BlogFlow', fetchCategory };
  } else {
    let category;
    const publicData = createPublicDataSelectors(config.publicData);

    const registerWidget = () => {
      const widgetState = widgetStore.getState();

      baseRegisterWidget(flowAPI, {
        resourceId: widgetState.resourceId,
        categoryId: widgetState.category?.id,
        pageId: wixCodeApi.site.currentPage?.id,
      });
    };

    const resourceIdStrategy = await resolveResourceIdStrategy({
      isDemoMode,
      isPageDynamic: await isPageDynamic(wixCodeApi),
      hasCategoryId: !!publicData.getCategoryId(),
    });

    try {
      category = await fetchCategory(publicData.getCategoryId());
    } catch (e) {
      if (e instanceof Error) {
        flowAPI.errorMonitor?.captureException(e, { tags: { context: 'fetchCategory' } });
      }
    }

    widgetStore.setState({
      resourceId: await resolveResourceId({
        compId,
        wixCodeApi,
        strategy: resourceIdStrategy,
      }),
      category,
    });

    wixCodeApi.location.onChange(async () => {
      widgetStore.setState({
        resourceId: await resolveResourceId({
          compId,
          wixCodeApi,
          strategy: resourceIdStrategy,
        }),
      });
    });

    widgetStore.subscribe((newState) => {
      if (newState.resourceId) {
        registerWidget();
      }
    });

    registerWidget();

    return {
      type: 'DefaultFlow',
      fetchCategory: () => fetchCategory(publicData.getCategoryId()),
      registerWidget,
    };
  }
};
