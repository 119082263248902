import { IWixAPI } from '@wix/yoshi-flow-editor';

export const resolveWidgetPath = (wixCodeApi: IWixAPI) => {
  const fallbackPath = '/';
  if (wixCodeApi.site.currentPage?.isHomePage) {
    return wixCodeApi.site.currentPage?.url ?? fallbackPath;
  }

  const basePath = (() => {
    const path = new URL(wixCodeApi.location.baseUrl).pathname;
    return path.endsWith('/') ? path.slice(0, -1) : path;
  })();

  const fullPath = new URL(wixCodeApi.location.url).pathname;

  const widgetPath = fullPath.replace(basePath, '');
  return widgetPath === '' ? fallbackPath : widgetPath;
};
