import { Mocks } from '@wix/comments-ooi-client/controller';
import { generateComment, getRichContent, getMember, generateReactions } from './utils';
import { take } from 'lodash';

// @TODO add current member?

export const getDemoContent = ({
  baseUrl,
  t,
}: {
  baseUrl: string;
  t: (key: string) => string;
}): Mocks['fetchComments'] => {
  const BASE_URL = baseUrl ? `${baseUrl}/assets/` : '';

  const generateDemoContent = ({
    commentsToShow,
    repliesToShow,
  }: {
    commentsToShow?: number;
    repliesToShow?: number;
  }) => {
    const Members = {
      commenter01: {
        id: '4a14f082-0da5-11ed-861d-0242ac120002',
        nickname: t('demoContent.commenterName'),
        url: `${BASE_URL}demo/avatar_1.jpg`,
      },
      commenter02: {
        id: '4a14f258-0da5-11ed-861d-0242ac120002',
        nickname: t('demoContent.commenterName'),
        url: `${BASE_URL}demo/avatar_2.jpg`,
      },
      replier01: {
        id: '4a14f41a-0da5-11ed-861d-0242ac120002',
        nickname: t('demoContent.replierName'),
        url: `${BASE_URL}demo/avatar_4.jpg`,
      },
      replier02: {
        id: '4a14f500-0da5-11ed-861d-0242ac120002',
        nickname: t('demoContent.replierName'),
        url: `${BASE_URL}demo/avatar_5.jpg`,
      },
      replier03: {
        id: '4a14f33e-0da5-11ed-861d-0242ac120002',
        nickname: t('demoContent.replierName'),
        url: `${BASE_URL}demo/avatar_3.jpg`,
      },
    };

    const comments = [
      generateComment({
        memberId: Members.commenter01.id,
        richContent: getRichContent(t('demoContent.comment01')),
        isMarked: true,
        rating: 5,
      }),
      generateComment({
        memberId: Members.commenter02.id,
        richContent: getRichContent(t('demoContent.comment02')),
        rating: 3,
      }),
    ];

    const commentsWithReplies = [
      {
        parentCommentId: comments[0].id,
        replies: [
          generateComment({
            memberId: Members.replier02.id,
            richContent: getRichContent(t('demoContent.reply02')),
            replyingToMemberId: Members.replier01.id,
          }),
          generateComment({
            memberId: Members.replier03.id,
            richContent: getRichContent(t('demoContent.reply01')),
            replyingToMemberId: comments[1].author.memberId,
          }),
          generateComment({
            memberId: Members.replier02.id,
            richContent: getRichContent(t('demoContent.reply01')),
            replyingToMemberId: comments[1].author.memberId,
          }),
        ],
      },
      { parentCommentId: comments[1].id, replies: [] },
    ];

    return {
      response: {
        comments: take(comments, commentsToShow),
        commentReplies: generateReplies(commentsWithReplies, repliesToShow),
        pagingMetadata: {
          count: 1,
          cursors: {},
          hasNext: false,
        },
      },
      additionalData: {
        registrationData: {
          registrationOpened: true,
          registrationManuallyApproved: false,
          requireEmailVerification: false,
        },
        contextPermissions: {
          viewContext: true,
          createComment: true,
          createReply: true,
          updateAnyComment: true,
          deleteAnyComment: true,
          rateResource: true,
          markComment: true,
          voteComment: true,
          reportComment: true,
          reactToComment: true,
        },
        votes: [],
        ratingsSummary: { total: 4, breakdown: { 1: 1, 2: 0, 3: 2, 4: 0, 5: 1 } },
        memberRoles: [{ memberId: Members.commenter01.id, roles: ['OWNER'] }],
        totalComments: 5,
        followedMemberIds: [],
        members: [
          getMember(Members.commenter01),
          getMember(Members.commenter02),
          getMember(Members.replier03),
          getMember(Members.replier01),
          getMember(Members.replier02),
        ],
        reactions: generateReactions([
          {
            commentId: comments[0].id,
            total: 14,
            reactionIdentities: [
              { memberId: Members.replier01.id },
              { memberId: Members.replier02.id },
              { memberId: Members.replier03.id },
            ],
          },
          {
            commentId: commentsWithReplies.find((c) => c.parentCommentId === comments[0].id)
              ?.replies[0].id!,
            total: 3,
            reactionIdentities: [{ memberId: Members.commenter02.id }],
          },
          {
            commentId: commentsWithReplies.find((c) => c.parentCommentId === comments[0].id)
              ?.replies[1].id!,
            total: 10,
            reactionIdentities: [
              { memberId: Members.replier01.id },
              { memberId: Members.commenter01.id },
            ],
          },
        ]),
        memberBadges: baseUrl
          ? [
              {
                [Members.commenter01.id]: [
                  {
                    id: '2c56aff6-3856-4e15-8546-659abca10a58',
                    title: 'Unicorn Badge',
                    textColor: '#FFFFFF',
                    icon: `${baseUrl}/assets/demo/unicorn-badge.svg`,
                  },
                ],
              },
            ]
          : [],
      },
    };
  };

  return async (req) => {
    return generateDemoContent({
      commentsToShow: req.request?.cursorPaging?.limit ?? 0,
      repliesToShow: req.request?.cursorPaging?.repliesLimit ?? 0,
    });
  };
};

const generateReplies = (
  repliesForComment: {
    parentCommentId: string;
    replies: { id: string }[];
  }[],
  repliesToShow?: number,
) => {
  return repliesForComment.reduce((acc, data) => {
    const repliesShown = take(data.replies, repliesToShow ?? data.replies.length);
    acc[data.parentCommentId] = {
      replies: repliesShown,
      pagingMetadata: {
        cursors: {
          next: data.replies.length > repliesShown.length ? 'cursor' : undefined,
          prev: undefined,
        },
      },
    };
    return acc;
  }, {} as Record<string, { replies: { id: string }[]; pagingMetadata: { cursors: { next: string | undefined; prev: string | undefined } } }>);
};
