import { IWixAPI } from '@wix/yoshi-flow-editor';

export const isPageDynamic = async (wixCodeApi: IWixAPI) => {
  const { site: wixSite, location } = wixCodeApi;

  if (!wixSite.currentPage) {
    return false;
  }

  const currentPagePrefixEntry = Object.entries(wixSite.currentPage).find(([key]) => {
    return key === 'prefix';
  });

  if (!currentPagePrefixEntry || typeof currentPagePrefixEntry[1] !== 'string') {
    return false;
  }
  const currentPagePrefix = currentPagePrefixEntry[1];
  const isDynamicPageType = Boolean(
    (await wixSite.getSiteStructure()).prefixes.find((item) => {
      return item.prefix === `/${currentPagePrefix}` && item.type === 'dynamicPages';
    }),
  );

  return isDynamicPageType && isPageDynamicPageList(location.url, currentPagePrefix);
};

const isPageDynamicPageList = (url: string, prefix: string) => {
  const splitUrl = url.split('/');
  const prefixIndex = url.split('/').indexOf(prefix);
  const isLastCharSlash = url.endsWith('/');

  return isLastCharSlash
    ? prefixIndex !== splitUrl.length - 2
    : prefixIndex !== splitUrl.length - 1;
};
