import { CommentsControllerApi } from '@wix/comments-ooi-client/controller';

export interface CommentsApiHolder {
  getApi: () => Promise<CommentsControllerApi | undefined>;
  setApi: (newApi: CommentsControllerApi) => void;
}

type ResolveApiFn = (api: CommentsControllerApi | undefined) => void;

export const createCommentsApiHolder = (): CommentsApiHolder => {
  let resolveApi: ResolveApiFn | undefined;
  let api: CommentsControllerApi | undefined;

  return {
    getApi: () =>
      new Promise((resolve) => {
        if (api) {
          resolve(api);
        } else {
          resolveApi = resolve;
          setTimeout(() => {
            resolve(undefined);
            resolveApi = undefined;
          }, 5000);
        }
      }),
    setApi: (newApi: CommentsControllerApi) => {
      if (!api) {
        api = newApi;
        if (resolveApi) {
          resolveApi(newApi);
        }
      }
    },
  };
};
